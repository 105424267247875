import React from "react";
import Modal from "../../../components/Modals";
import requests from "../../../services/requests";

const ModalShowFile = ({ actionModal, openModal, entity }) => {
    let linkDocument = (
        requests.getApiUrl() + `/open/process/file/${entity?.processIdentifier}/${entity?.identifier}/view`
    ).replace("//open", "/open");

    if (entity?.ext === "docx" || entity?.ext === "xlsx" || entity?.ext === "pptx") {
        linkDocument = "https://view.officeapps.live.com/op/embed.aspx?src=" + linkDocument;
    }

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-12/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>{entity?.name}</h5>
                </div>
            }
        >
            <iframe width="100%" height="700rem" src={linkDocument} frameBorder="0" allowFullScreen={true}></iframe>
        </Modal>
    );
};

//
//
export default ModalShowFile;
