import styled from "styled-components";
import { colors } from "../../../../../../assets/colors";

export default styled.article`
    svg {
        color: ${colors.roxo_oficial};
        cursor: pointer;
    }
    img {
        color: ${colors.roxo_oficial};
        cursor: pointer;
    }
`;
