import React, { useCallback, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { format } from "date-fns";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";

import SearchIcon from "../../../../../../assets/svgs/search.inline.svg";
import DownloadIcon from "../../../../../../assets/svgs/download.inline.svg";
import Table from "../../../../../../components/styles/Table";
import Paginate from "../../../../../../components/Paginate/Paginate";
import requests from "../../../../../../services/requests";
import ModalDocsUpload from "../ModalDocsUpload";
import Container from "./styles";

import ModalRemoveFile from "../../../../components/ModalRemoveFile";
import ModalShowFile from "../../../../components/ModalShowFile";

function FormatString(name) {
    let nome = name.toString();

    if (nome.length > 60) {
        nome = nome.substr(0, 60);
    }

    return nome;
}

function DocsTable({ process }) {
    // Loaders
    const [downloadingFile, setDownloadingFile] = useState("");
    const [downloadingAll, setDownloadingAll] = useState(false);
    const [loading, setLoading] = useState(true);

    // List
    const [data, setData] = useState([]);
    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    // Modal remove
    const [currentEntity, setCurrentEntity] = useState(null);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const actionModalRemove = entity => {
        setCurrentEntity(entity);
        setOpenModalRemove(!openModalRemove);
    };
    const closeModalRemove = () => {
        setOpenModalRemove(false);
    };

    // Modal show
    const [currentDocument, setCurrentDocument] = useState(null);
    const [openModalShow, setOpenModalShow] = useState(false);
    const actionModalShow = document => {
        setCurrentDocument(document);
        setOpenModalShow(!openModalShow);
    };
    const closeModalShow = () => {
        setOpenModalShow(false);
    };

    const downloadFile = useCallback((identifier, filename, ext) => {
        setDownloadingFile(identifier);

        let link = document.createElement("a");

        link.href = (requests.getApiUrl() + `/open/process/file/${process.identifier}/${identifier}/download`).replace(
            "//open",
            "/open"
        );
        link.target = "_blank";
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadingFile("");
    });

    const downloadAllFiles = useCallback(() => {
        setDownloadingAll(true);

        let link = document.createElement("a");

        link.href = (requests.getApiUrl() + `/open/process/file/${process.identifier}/all/download`).replace(
            "//open",
            "/open"
        );
        link.target = "_blank";
        link.download = process.identifier + ".zip";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadingAll(false);
    });

    const loadList = () => {
        //Get Data
        requests.getFilesListByProcess(process.identifier, meta?.current_page).then(response => {
            const aux = response.map(doc => {
                const { identifier, uploadName, createdAt, uri, user, stage, modality, processIdentifier } = doc;
                const newCreatedAt = new Date(createdAt);
                const arrName = uploadName.split(".");
                const ext = arrName.pop();
                const name = arrName;
                const date = format(newCreatedAt, "dd/MM/yyyy");
                const hours = format(newCreatedAt, "HH:mm");
                const postedBy = user?.name;

                return { identifier, name, ext, date, hours, uri, postedBy, stage, modality, processIdentifier };
            });

            setData(aux);
            setMeta(response.meta);
            setLoading(false);
        });
    };

    useEffect(() => {
        loadList();
    }, [action]);

    return (
        <Container>
            <ModalRemoveFile
                actionModal={closeModalRemove}
                openModal={openModalRemove}
                entity={currentEntity}
                handleSuccess={() => {
                    closeModalRemove();
                    loadList();
                }}
            />
            <ModalShowFile
                actionModal={closeModalShow}
                openModal={openModalShow}
                entity={currentDocument}
                handleSuccess={() => {
                    closeModalShow();
                }}
            />
            <article className="flex mt-3 justify-between ">
                <h5>Documentos</h5>
                <div className="flex">
                    {!loading && data.length > 0 && (
                        <button
                            className="p-1 pl-3 pr-3 mr-2 rounded-md bg-roxo_oficial_light"
                            style={{
                                fontSize: "14px",
                                fontWeight: "550",
                            }}
                            onClick={() => downloadAllFiles()}
                        >
                            {!downloadingAll ? <>Baixar todos os documentos</> : <ClipLoader size={20} loading={true} />}
                        </button>
                    )}
                    <ModalDocsUpload process={process} onSuccess={loadList} />
                </div>
            </article>
            {loading ? (
                <div>
                    <ClipLoader size={20} loading={true} />
                </div>
            ) : (
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: "35%" }}>Nome</th>
                            <th style={{ width: "9%" }}>Extensão</th>
                            <th style={{ width: "9%" }}>Data</th>
                            <th style={{ width: "5%" }}>Hora</th>
                            <th style={{ width: "10%" }}>Postado por</th>
                            <th style={{ width: "10%" }}>Etapa</th>
                            <th style={{ width: "10%" }}>Tipo</th>
                            <th style={{ width: "12%" }} className="text-center">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(doc => (
                            <tr key={doc.identifier}>
                                <td title={doc.name}>{FormatString(doc.name)}</td>
                                <td>{doc.ext}</td>
                                <td>{doc.date}</td>
                                <td>{doc.hours}</td>
                                <td>{doc.postedBy}</td>
                                <td>{doc.stage?.description}</td>
                                <td>{doc.modality?.description}</td>
                                <td className="text-center">
                                    <button
                                        className="p-2 mr-2 mt-2 rounded-md bg-C2"
                                        title="Visualizar documento"
                                        onClick={() => actionModalShow(doc)}
                                    >
                                        <SearchIcon />
                                    </button>
                                    <button
                                        className="p-2 mr-2 mt-2 rounded-md bg-roxo_oficial_light"
                                        title="Baixar documento"
                                        onClick={() => downloadFile(doc.identifier, doc.name, doc.ext)}
                                    >
                                        {!downloadingFile || downloadingFile !== doc.identifier ? (
                                            <DownloadIcon />
                                        ) : (
                                            <ClipLoader size={20} loading={true} />
                                        )}
                                    </button>
                                    <button
                                        className="p-2 mt-2 rounded-md bg-canal_vermelho"
                                        title="Excluir documento"
                                        onClick={() => actionModalRemove(doc)}
                                    >
                                        <FaTrashAlt color="#fff" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {(!data || data.length === 0) && (
                            <tr className="text-center">
                                <td className="text-center" colSpan="10">
                                    Sem documentos neste processo.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}
            {meta && meta.total_pages > 1 && (
                <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} showDetails={true} />
            )}
        </Container>
    );
}

export default DocsTable;
